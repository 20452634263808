(function ($) {
  Drupal.behaviors.ELB_mp_translate_CA_FR = {
    attach: function (context, settings) {
      // Update language links to keep current page URL.
      $('a[data-mp-lang]', context).each(function () {
        const $link = $(this);
        const businessUnitId = settings.elc_ecom?.businessUnitId;
        const buidLang = businessUnitId?.match(/^\d+-[a-z]+-[a-z]+-([a-z]+)-.+$/u);
        const currLang = buidLang ? buidLang[1]?.trim() : null;
        const targetLang = $link.attr('data-mp-lang')?.trim();
        const currentUrl = window.location.href;
        const currentUrlParts = currentUrl.split('://');
        const isMobile = currentUrl.includes('://m.');
        const domainPrefix = isMobile ? '://m.' : '://www.';

        $link.attr('href', currentUrl);

        if (currLang !== targetLang) {
          $link.attr('href', function () {
            if (targetLang === 'en') {
              return currentUrl.replace(/:\/\/(m\.)?[a-z]+\./u, domainPrefix);
            }

            if (currentUrl.includes('://www.')) {
              return currentUrl.replace('://www.', `://${targetLang}.`);
            }

            if (isMobile) {
              return currentUrl.replace('://m.', `://m.${targetLang}.`);
            }

            return `${currentUrlParts[0]}://${targetLang}.${currentUrlParts[1]}`;
          });
        }
      });
    }
  };
})(jQuery);
